//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
//获取品牌列表接口
export function getbrandData(obj,url) {
    return request({
        url: '/api/admin/brand',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增品牌接口
export function postaddBrand(obj,url) {
    return request({
        url: '/api/admin/brand/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑品牌接口
export function posteditBrand(obj,url) {
    return request({
        url: '/api/admin/brand/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除品牌接口
export function postdelBrand(obj,url) {
    return request({
        url: '/api/admin/brand/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

